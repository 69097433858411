import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import styled from "styled-components";

import { Container, Wrapper } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AgreementContent = styled.div`
  ol {
    list-style-type: upper-alpha;
  }
`;

export const query = graphql`
  query {
    contentfulUserAgreement(type: { eq: "Terms" }) {
      lastModified(formatString: "MMMM D, YYYY")
      title
      content {
        raw
      }
      version
    }
  }
`;

const Terms = (props) => {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (props.location.search.substr(1) === "reload") {
        window.history.pushState(
          {},
          null,
          props.location.origin + props.location.pathname
        );
        window.location.reload(true);
      }
    }
  }, [props.location.origin, props.location.pathname, props.location.search]);

  const website_url = process.env.GATSBY_SITE_URL;

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        if (
          node.data.uri.startsWith(website_url) ||
          node.data.uri.startsWith("/") ||
          node.data.uri.startsWith("#")
        ) {
          return (
            <Link to={node.data.uri}>
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </Link>
          );
        } else {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {node.content.map((edge, i) => {
                return <span key={i}>{edge.value}</span>;
              })}
            </a>
          );
        }
      },
    },
  };

  return (
    <Layout>
      <Seo
        title="Terms Of Use | FlexiTime"
        description="FlexiTime Terms of Use. We provide the Software to you subject to these Terms of Use (“Terms”), which may be updated by us from time to time pursuant to Section 1."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80} maxWidth={1000}>
          <h1 className="-textCenter">
            FlexiTime {props.data.contentfulUserAgreement.title}
          </h1>
          <AgreementContent className="font-bump">
            {renderRichText(
              props.data.contentfulUserAgreement.content,
              options
            )}
          </AgreementContent>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Terms;
